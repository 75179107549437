"use client"

import React from "react"
import Image from "next/image"
import { useRouter } from "next/navigation"

import { WobbleCard } from "../ui/wobble-card"

export default function ShopByCategory() {
  const router = useRouter()

  const redirections = {
    "limited-edition": "/products?isLimitedEdition=true",
    men: "/products?fit=MEN",
    women: "/products?fit=WOMEN",
    "designer-collection": "/products?isDesignerCollection=true",
  }

  const redirect = (redirectTo: string) => router.push(redirectTo)

  return (
    <section className="px-2 md:container">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-[14px] md:text-[32px] text-[#444] text-center mb-5">
          Shop by{" "}
          <span className="text-[20px] md:text-[40px] font-semibold">
            Category
          </span>
        </h2>
      </div>

      <div className="flex flex-col items-start justify-center">
        <div className="w-full flex items-start justify-stretch pt-2 md:pt-10 md:gap-10 gap-2">
          <div className="w-1/2 md:w-[75%]">
            <WobbleCard
              containerClassName="col-span-1 lg:col-span-2 h-full bg-slate-100 md:h-[20rem] cursor-pointer"
              className="relative"
              onClick={() => redirect(redirections["limited-edition"])}
            >
              <img
                src="/assets/images/card_1.jpg"
                alt="linear demo image"
                className="object-cover rounded-2xl"
              />
              <div className="absolute inset-0 top-3/4 bg-black rounded-b-2xl opacity-50" />
              <div className="absolute inset-0 top-3/4 flex flex-col items-center justify-center">
                <h6 className="text-white font-medium ">Supima</h6>
              </div>
            </WobbleCard>
          </div>
          <div className="w-1/2 md:w-[25%]">
            <WobbleCard
              containerClassName="col-span-1 lg:col-span-2 bg-slate-100 h-full md:h-[43rem] cursor-pointer"
              className="relative"
              onClick={() => redirect(redirections["designer-collection"])}
            >
              <img
                src="/assets/images/hero_banner.jpeg"
                alt="linear demo image"
                className="object-cover rounded-2xl"
              />
              <div className="absolute inset-0 top-3/4 bg-black rounded-b-2xl opacity-50" />
              <div className="absolute inset-0 top-3/4 flex flex-col items-center justify-center">
                <h6 className="text-white text-sm font-medium">
                  Limited Edition
                </h6>
              </div>
            </WobbleCard>
          </div>
        </div>

        <div className="w-full md:w-[73%] flex items-center justify-stretch md:gap-10 gap-2 mt-2 md:-mt-80">
          <WobbleCard
            containerClassName="col-span-1 lg:col-span-2 h-full bg-slate-100 md:h-[20rem] cursor-pointer"
            className="relative"
            onClick={() => redirect(redirections["men"])}
          >
            <img
              src="/assets/images/apparel_1.jpeg"
              alt="linear demo image"
              className="object-cover rounded-2xl"
            />
            <div className="absolute inset-0 top-3/4 bg-black rounded-b-2xl opacity-50" />
            <div className="absolute inset-0 top-3/4 flex flex-col items-center justify-center">
              <h6 className="text-white text-sm font-medium">Men</h6>
            </div>
          </WobbleCard>
          <WobbleCard
            containerClassName="col-span-1 lg:col-span-2 h-full bg-slate-100 md:h-[20rem] cursor-pointer"
            className="relative"
            onClick={() => redirect(redirections["women"])}
          >
            <img
              src="/assets/images/apparel_2.jpeg"
              alt="linear demo image"
              className="object-cover rounded-2xl"
            />
            <div className="absolute inset-0 top-3/4 bg-black rounded-b-2xl opacity-50" />
            <div className="absolute inset-0 top-3/4 flex flex-col items-center justify-center">
              <h6 className="text-white text-sm font-medium">Women</h6>
            </div>
          </WobbleCard>
        </div>
      </div>
    </section>
  )
}
