import Image from "next/image"

const Features = () => {
  return (
    <div className="mb-12 grid grid-cols-2 gap-y-5 p-8 md:flex md:px-[12rem] justify-evenly items-start py-10 bg-gray-100 font-medium text-[#444]">
      <div className="flex flex-col items-center">
        <img
          src={"/assets/images/feature1.png"}
          height={50}
          width={50}
          className="rounded-full object-contain h-[50px] w-[50px] bg-[#25492D] "
          alt="Plant Based Fabrics"
        />

        <p className="text-[12px] md:text-[20px] text-center">
          Plant Based Fabrics
        </p>
      </div>
      <div className="flex flex-col items-center">
        <img
          src={"/assets/images/feature2.png"}
          height={50}
          width={50}
          className="rounded-full object-contain h-[50px] w-[50px] bg-[#25492D] "
          alt="Toxic & Plastic Free"
        />
        <p className="text-[12px] md:text-[20px] text-center">
          Toxic & Plastic Free
        </p>
      </div>
      <div className="flex flex-col items-center">
        <img
          src={"/assets/images/feature3.png"}
          height={50}
          width={50}
          className="rounded-full object-contain h-[50px] w-[50px] bg-[#25492D] "
          alt="Zero Waste"
        />
        <p className="text-[12px] md:text-[20px] text-center">Zero Waste</p>
      </div>
      <div className="flex flex-col items-center">
        <img
          src={"/assets/images/feature4.png"}
          height={50}
          width={50}
          className="rounded-full object-contain h-[50px] w-[50px] bg-[#25492D] "
          alt="Transparency"
        />
        <p className="text-[12px] md:text-[20px] text-center">Transparency</p>
      </div>
    </div>
  )
}

export default Features
