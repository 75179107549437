import { useRef, useState } from "react"
import { Hurricane, Poppins } from "next/font/google"
import Image from "next/image"
import Link from "next/link"
import { event } from "nextjs-google-analytics"
import { useWindowSize } from "react-use"

import { cn } from "@/lib/utils"

import { S3Constants } from "../../constants/s3.constants"
import Search from "../layout/header/search"

useWindowSize

const hurricane = Hurricane({
  subsets: ["latin"],
  weight: "400",
  display: "swap",
  style: "normal",
})

const poppins = Poppins({
  subsets: ["latin"],
  weight: "400",
  display: "swap",
  style: "normal",
})

const Hero: React.FC = () => {
  const [isMuted, setIsMuted] = useState<boolean>(true)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const { width } = useWindowSize()

  const onClickShopNow = () => {
    event("button_click", {
      category: "navigation_home_screen_banner",
      label: "Shop Now",
    })
  }

  const toggleMute = () => {
    setIsMuted(!videoRef.current?.muted)
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted
    }
  }

  const getVideoLink = () => {
    const link = `${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_URL}${width > 768 ? S3Constants.HERO_WEBSITE_VIDEO_PATH : S3Constants.HERO_MOBILE_VIDEO_PATH}`
    return link
  }

  return (
    <section className="relative  w-full">
      <Search className="md:hidden inline-block my-2 w-full" />
      <video
        ref={videoRef}
        src={getVideoLink()}
        autoPlay
        muted={isMuted}
        data-wf-ignore="true"
        data-object-fit="cover"
        loop
        playsInline
        className="w-full h-auto object-cover"
      />
      <button
        onClick={toggleMute}
        className="absolute bottom-4 right-4 p-2 bg-gray-800 text-white rounded-full shadow-lg"
        aria-label={isMuted ? "Unmute video" : "Mute video"}
      >
        {isMuted ? (
          <span role="img" aria-label="Sound off">
            🔇
          </span>
        ) : (
          <span role="img" aria-label="Sound on">
            🔊
          </span>
        )}
      </button>
      {/* Uncomment and adjust as needed */}
      {/* <div className="relative md:mt-10 h-[80vh]">
        <img
          className="h-64 w-full object-cover rounded-[24px]"
          src="/assets/images/hero_banner.jpeg"
          alt="Random image"
          fill
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <h2
            className={cn(
              "text-white md:text-[200px] -mb-12 text-[52px] font-bold",
              poppins.className
            )}
          >
            SUSTAINABLE
          </h2>
          <h2
            className={cn(
              "text-white w-[90%] md:text-[144px] text-[32px] font-bold",
              hurricane.className
            )}
          >
            fashion
          </h2>
        </div>
      </div> */}
      {/* Uncomment and adjust as needed */}
      {/* <div className="mt-5 flex justify-center items-center gap-x-6">
        <Link
          href="/products"
          className="rounded-full bg-gradient-to-br from-green-800 to-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={onClickShopNow}
        >
          Shop Now
        </Link>
      </div> */}
    </section>
  )
}

export default Hero
