"use client"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { useRef, useState } from "react"
import Image from "next/image"
import { LuChevronLeft, LuChevronRight } from "react-icons/lu"
import Slider from "react-slick"

import { Button } from "../ui/button"

const Banner = () => {
  const [slideIndex, setSlideIndex] = useState(0)
  const sliderRef = useRef<any>(null)

  function CustomPrevArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div className="absolute top-2/4 left-5 z-20 cursor-pointer transform -translate-y-2/4">
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full bg-gray-700/50"
          onClick={onClick}
        >
          <LuChevronLeft className="w-10 h-10" color="white" />
        </Button>
      </div>
    )
  }

  function CustomNextArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div className="absolute top-2/4 right-5 z-50 cursor-pointer transform -translate-y-2/4">
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full bg-gray-700/50"
          onClick={onClick}
        >
          <LuChevronRight className="w-10 h-10" color="white" />
        </Button>
      </div>
    )
  }

  return (
    <section className="px-2 md:container my-20 md:my-40 md:px-10">
      <Slider
        lazyLoad="ondemand"
        infinite
        slidesToShow={1}
        cssEase="ease-in-out"
        slidesToScroll={1}
        fade
        ref={sliderRef}
        draggable
        pauseOnHover
        swipeToSlide
        arrows
        className="rounded-2xl h-60 md:h-80 overflow-hidden"
        prevArrow={<CustomPrevArrow />}
        nextArrow={<CustomNextArrow />}
        beforeChange={(curr, next) => setSlideIndex(next)}
        dotsClass="slick-dots slick-thumb"
        dots
        customPaging={(i) => (
          <span
            key={i}
            className={`block h-1 mt-4 mx-auto cursor-pointer rounded-2xl z-10 ${
              slideIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
            }`}
            onClick={() => sliderRef?.current?.slickGoTo(i)}
          />
        )}
      >
        <div className="relative h-full w-full">
          <img
            src="/assets/images/hero_banner.jpeg"
            className="h-60 md:h-80 w-full object-fill"
            alt={`Slide`}
            width={100}
            height={100}
          />
          <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/30 text-gray-200">
            <span className="absolute bottom-[10%] left-1/2 -translate-x-1/2 -translate-y-1/2">
              Eco-conscious practices from production to packaging.
            </span>
          </div>
        </div>
        <img
          width={100}
          height={100}
          src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
          alt="image 1"
          className="h-60 md:h-80 w-full object-fill"
        />
        <img
          width={100}
          height={100}
          src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
          alt="image 2"
          className="h-60 md:h-80 w-full object-fill"
        />
        <img
          width={100}
          height={100}
          src="https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80"
          alt="image 3"
          className="h-60 md:h-80 w-full object-fill"
        />
      </Slider>
    </section>
  )
}

export default Banner
