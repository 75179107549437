"use client"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { useEffect, useRef, useState } from "react"
import Image from "next/image"
import Link from "next/link"
import { event } from "nextjs-google-analytics"
import Slider from "react-slick"

// adjust the import path
import ShopByFabric from "./ShopByFabric" // adjust the import path

const FabricDescSlider = ({
  sliderRef,
  fabrics,
}: {
  sliderRef: any
  fabrics: {
    id: number
    name: string
    image: string
    description: string
    redirectTo: string
  }[]
}) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [updateCount, setUpdateCount] = useState(0)

  const onClickShopNow = () => {
    event("button_click", {
      category: "navigation_home_screen_fabric",
      label: "Shop",
    })
  }

  const slides = fabrics.map((fabric, index) => (
    <div
      key={fabric.id}
      className="relative h-full w-full py-[2rem] px-5 md:py-20 text-gray-200 !flex md:flex-row flex-col items-center justify-center"
    >
      <div className="md:w-[30%] w-full grid place-content-center">
        <img
          src={fabric.image}
          className="h-[15rem] w-[15rem] object-cover rounded-xl"
          alt={fabric.name}
          width={800}
          height={800}
        />
      </div>
      <div className="md:w-[30%] md:mt-0 mt-3">
        <h2 className="text-[16px] md:text-[40px] text-center md:text-left font-semibold">
          {fabric.name}
        </h2>
        <p className="text-[12px] md:text-[16px] mt-4 mb-6 text-justify md:text-left">
          {fabric.description}
        </p>

        <div className="text-center md:text-left md:mb-0 mb-2">
          {fabric.name == "Organic Cotton" || fabric.name == "Supima Cotton" ? (
            <Link
              href={fabric.redirectTo}
              className="rounded-full bg-white text-[14x] md:text-[16px] px-8 py-2 font-medium text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={onClickShopNow}
            >
              Shop
            </Link>
          ) : (
            <p>Coming soon</p>
          )}
        </div>
      </div>
    </div>
  ))

  return (
    <div className="slider-container bg-gradient-to-br from-green-700 to-green-900 h-fit">
      <Slider
        lazyLoad="ondemand"
        infinite
        slidesToShow={1}
        cssEase="ease-in-out"
        slidesToScroll={1}
        initialSlide={4}
        fade
        draggable
        pauseOnHover
        swipeToSlide
        ref={sliderRef}
        className=" md:bg-gradient-to-br from-green-700 to-green-900 rounded-[2rem] overflow-hidden h-fit"
        dotsClass="slick-dots slick-thumb"
      >
        {slides}
      </Slider>
    </div>
  )
}

export default FabricDescSlider
