export const fabrics = [
  {
    id: 1,
    name: "Organic Cotton",
    image: "/assets/images/fabrics/COTTON 1.jpg",
    description:
      "Organic cotton is grown without the use of harmful pesticides or synthetic fertilizers, making it an eco-friendly and sustainable choice. This natural fiber is not only better for the environment but also gentler on your skin. Organic cotton farming practices enhance soil health, conserve water, and support biodiversity. The result is a soft, durable, and breathable fabric that is perfect for everyday wear, ensuring comfort while promoting a healthier planet. Choose organic cotton for a stylish and conscious wardrobe choice.",
    redirectTo: "/products?fabric=ORGANICCOTTON",
  },
  {
    id: 2,
    name: "Supima Cotton",
    image: "/assets/images/fabrics/SUPIMA.jpg",
    description:
      "Supima cotton represents the pinnacle of luxury and quality in the cotton world. With extra-long staple fibers, Supima cotton is incredibly soft, strong, and durable. This premium fiber is twice as strong as regular cotton, ensuring your garments maintain their shape and resist pilling over time. Supima cotton also absorbs dye better, resulting in vibrant, long-lasting colors. Ethically grown in the USA, Supima cotton supports sustainable farming practices. Choose Supima cotton for an unmatched combination of comfort, durability, and elegance.",
    redirectTo: "/products?fabric=SUPIMACOTTON",
  },
  {
    id: 3,
    name: "Hemp",
    image: "/assets/images/fabrics/HEMP.jpg",
    description:
      "Hemp is a versatile and sustainable fabric known for its strength and durability. As one of the most eco-friendly crops, hemp requires minimal water and no pesticides to grow, making it an excellent choice for environmentally conscious fashion. The fabric is naturally resistant to mold and UV rays, ensuring long-lasting wear. Hemp fibers soften with each wash, providing comfort while maintaining their integrity. Additionally, hemp is highly breathable and has excellent moisture-wicking properties, keeping you cool and comfortable. Choose hemp for a stylish, durable, and planet-friendly wardrobe.",
    redirectTo: "/products?fabric=Linen",
  },
  {
    id: 4,
    name: "Bamboo",
    image: "/assets/images/fabrics/BAMBOO 1.jpg",
    description:
      "Bamboo fabric is celebrated for its eco-friendly properties and luxurious feel. Grown without pesticides, bamboo is a highly sustainable crop that regenerates quickly, making it an excellent choice for sustainable fashion. The fabric derived from bamboo is incredibly soft, often compared to silk or cashmere, and offers natural antibacterial and moisture-wicking properties. It is highly breathable and temperature-regulating, keeping you cool in the summer and warm in the winter. Embrace bamboo fabric for a comfortable, sustainable, and versatile addition to your wardrobe.",
    redirectTo: "/products?fabric=Silk",
  },
  {
    id: 5,
    name: "Aloe Vera",
    image: "/assets/images/fabrics/ALOE VERA.jpg",
    description:
      "Aloe vera fabric is an innovative, eco-friendly textile infused with the natural benefits of the aloe vera plant. Known for its soothing and healing properties, aloe vera provides a gentle touch that is perfect for sensitive skin. The fabric is soft, breathable, and highly absorbent, making it ideal for comfortable, all-day wear. Additionally, aloe vera fabric retains the plant's natural antimicrobial properties, promoting hygiene and freshness. Embrace aloe vera fabric for a blend of comfort, sustainability, and skin-friendly benefits",
    redirectTo: "/products?fabric=Wool",
  },
  {
    id: 6,
    name: "Tencel",
    image: "/assets/images/fabrics/TENCEL - EUCALYPTUS.jpg",
    description:
      "Tencel, also known as lyocell, is a sustainable fabric made from the wood pulp of eucalyptus trees. This eco-friendly material is produced through a closed-loop process that recycles water and solvents, minimizing environmental impact. Tencel is renowned for its silky smooth texture, excellent breathability, and moisture-wicking properties, making it ideal for sensitive skin and active lifestyles. It is also biodegradable and compostable, reinforcing its eco-friendly credentials. Choose Tencel for a luxurious, sustainable, and environmentally conscious fabric.",
    redirectTo: "/products?fabric=Polyster",
  },

  {
    id: 7,
    name: "Linen",
    image: "/assets/images/fabrics/LINEN.jpg",
    description:
      "Linen is a timeless, sustainable fabric made from the fibers of the flax plant. Renowned for its natural strength and durability, linen is highly breathable and moisture-wicking, making it ideal for warm weather. Its unique texture softens with each wash, providing a comfortable and relaxed fit. Linen is also naturally hypoallergenic and resistant to bacteria, offering a clean and fresh wearing experience. Embrace linen for its classic appeal, eco-friendly properties, and exceptional comfort.",
    redirectTo: "/products?fabric=Nylon",
  },
  {
    id: 8,
    name: "Jute",
    image: "/assets/images/fabrics/JUTE.jpg",
    description:
      "Jute is a classic, eco-friendly fabric derived from the stalks of the jute plant. Celebrated for its natural strength and durability, jute offers a distinctive, earthy texture that enhances any garment. This breathable material is both robust and biodegradable, making it an excellent choice for sustainable fashion. Embrace jute for its timeless appeal, commitment to environmental responsibility, and long-lasting comfort.",
    redirectTo: "/products?fabric=Nylon",
  },
  {
    id: 9,
    name: "Lotus Silk",
    image: "/assets/images/fabrics/LOTUS.jpg",
    description:
      "Lotus silk is a luxurious, sustainable fabric made from the fibers of lotus plants. Renowned for its softness and natural sheen, lotus silk offers a unique texture that feels incredibly smooth against the skin. It is highly breathable and lightweight, making it ideal for elegant, comfortable wear. This eco-friendly fabric is also naturally hypoallergenic and biodegradable, aligning with our commitment to sustainability. Embrace lotus silk for its exquisite quality, sustainable origins, and sophisticated appeal.",
    redirectTo: "/products?fabric=Nylon",
  },
  {
    id: 10,
    name: "Rose",
    image: "/assets/images/fabrics/ROSE.jpg",
    description:
      "Rose fabric is a beautifully sustainable material crafted from the fibers of rose petals. Known for its soft, velvety texture and natural sheen, rose fabric offers a touch of elegance and luxury. It is lightweight, breathable, and gentle on the skin, making it a comfortable choice for stylish, eco-conscious fashion. This fabric is also biodegradable, supporting our commitment to sustainability. Choose rose fabric for its unique charm, sustainable qualities, and refined comfort.",
    redirectTo: "/products?fabric=Nylon",
  },
  {
    id: 11,
    name: "Orange",
    image: "/assets/images/fabrics/ORANGE.jpg",
    description:
      "Orange fabric is an eco-friendly material that harnesses the vibrant color of nature. Made from natural fibers, this fabric offers exceptional durability and resilience while maintaining a soft, comfortable feel. Its rich, warm hue is achieved through natural dyeing processes that align with our commitment to sustainability. The fabric is breathable, making it ideal for both everyday wear and special occasions. Additionally, it is biodegradable and supports environmentally responsible fashion. Embrace the dynamic appeal of orange fabric for its vivid color, sustainable qualities, and enduring comfort.",
    redirectTo: "/products?fabric=Nylon",
  },
  {
    id: 12,
    name: "Modal Slik",
    image: "/assets/images/fabrics/MODAL SILK.jpg",
    description:
      "Modal silk is a luxurious and sustainable fabric that combines the smooth elegance of silk with the eco-friendly properties of modal fibers. Crafted from beech tree pulp, modal is known for its exceptional softness, breathability, and moisture-wicking capabilities. When blended with silk, it enhances the fabric's natural sheen and drape, resulting in a silky-smooth texture that feels indulgent against the skin. Modal silk is also biodegradable and resistant to wrinkles, making it both an elegant and practical choice. Embrace modal silk for its refined quality, sustainable origins, and sophisticated comfort",
    redirectTo: "/products?fabric=Nylon",
  },
  {
    id: 13,
    name: "Ramie",
    image: "/assets/images/fabrics/RAMIE.jpg",
    description:
      "Ramie is a durable and eco-friendly fabric derived from the fibers of the ramie plant. Renowned for its strength and resistance to wrinkles, mildew, and staining, ramie offers a crisp, textured finish that adds a refined touch to any garment. Its natural breathability and moisture-wicking properties make it ideal for warm climates, while its ability to hold shape ensures a sleek and polished look. As a sustainable material, ramie is biodegradable and supports our commitment to environmental responsibility. Choose ramie for its resilience, elegant texture, and eco-conscious benefits",
    redirectTo: "/products?fabric=Nylon",
  },
  {
    id: 14,
    name: "Soy",
    image: "/assets/images/fabrics/SOY.jpg",
    description:
      'Soy fabric, often referred to as "vegetable cashmere," is a sustainable material made from the byproducts of soybeans. Known for its exceptional softness and luxurious feel, soy fabric provides a smooth, comfortable texture that rivals traditional cashmere. It is highly breathable, moisture-wicking, and gentle on the skin, making it ideal for both everyday wear and special occasions. Additionally, soy fabric is biodegradable and supports eco-friendly practices. Embrace soy fabric for its plush comfort, sustainable origins, and refined elegance.',
    redirectTo: "/products?fabric=Nylon",
  },
]
