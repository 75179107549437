"use client"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import React, { useEffect, useRef, useState } from "react"

import { fabrics } from "@/lib/fabrics" // adjust the import path

import { AnimatedTooltip } from "../ui/animated-tooltip"
import FabricDescSlider from "./FabricDescSlider" // adjust the import path

const ShopByFabric = () => {
  const sliderRef = useRef<any>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext()
      }
    }, 3000) // Change slide every 3 seconds

    return () => clearInterval(interval) // Cleanup on unmount
  }, [])

  return (
    <section className="my-20">
      <div className="container flex mb-4 flex-col items-center justify-center">
        <h2 className="text-[14px] md:text-[32px] text-[#444] mb-5">
          Shop by{" "}
          <span className="text-[20px] md:text-[40px] font-semibold">
            Fabric
          </span>
        </h2>
        <p className="text-[12px] md:text-[16px] text-[#a5a5a5] text-center">
          We have a wide range of fabrics to choose from.
          <br />
          Select your favorite fabric and get your favorite shirt.
        </p>
      </div>

      {/* <div className="my-12 py-4 flex flex-row flex-wrap md:gap-0 gap-8 items-center justify-evenly w-full"> */}
      <div>
        <AnimatedTooltip items={fabrics} sliderRef={sliderRef} />

        <FabricDescSlider sliderRef={sliderRef} fabrics={fabrics} />
      </div>
    </section>
  )
}

export default ShopByFabric
