"use client"

import React, { useRef, useState } from "react"
import Image from "next/image"
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion"
import { LuChevronLeft, LuChevronRight } from "react-icons/lu"
import Slider from "react-slick"
import { useWindowSize } from "react-use"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"

export const AnimatedTooltip = ({
  items,
  sliderRef,
}: {
  items: {
    id: number
    name: string
    image: string
  }[]
  sliderRef: any
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [activeIndex, setActiveIndex] = useState<number | null>(items[4].id)
  const { width } = useWindowSize()

  const springConfig = { stiffness: 100, damping: 5 }
  const x = useMotionValue(0) // going to set this value on mouse move
  // rotate the tooltip
  const rotate = useSpring(
    useTransform(x, [-100, 100], [-45, 45]),
    springConfig
  )
  // translate the tooltip
  const translateX = useSpring(
    useTransform(x, [-100, 100], [-50, 50]),
    springConfig
  )
  const handleMouseMove = (event: any) => {
    const halfWidth = event.target.offsetWidth / 2
    x.set(event.nativeEvent.offsetX - halfWidth) // set the x value, which is then used in transform and rotate
  }

  function CustomPrevArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div className="absolute top-2/4 -left-5 md:left-0 z-50 cursor-pointer transform -translate-y-2/4">
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full bg-green-300 hover:bg-green-500"
          onClick={onClick}
        >
          <LuChevronLeft className="w-5 h-5" color="white" />
        </Button>
      </div>
    )
  }

  function CustomNextArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div className="absolute top-2/4 -right-5 md:right-0 z-50 cursor-pointer transform -translate-y-2/4">
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full bg-green-300 hover:bg-green-500"
          onClick={onClick}
        >
          <LuChevronRight className="w-5 h-5" color="white" />
        </Button>
      </div>
    )
  }

  const getOpacityClass = (index: number) => {
    const totalItems = items.length
    const distance = Math.abs(index - activeIndex!) % totalItems
    switch (distance) {
      case 0:
        return "opacity-100 scale-150"
      case 1:
        return "opacity-80 scale-125"
      case 2:
        return "opacity-40 scale-100"
      default:
        return "opacity-20 scale-75"
    }
  }

  return (
    <>
      <Slider
        lazyLoad="ondemand"
        ref={sliderRef}
        infinite
        initialSlide={4}
        centerMode
        focusOnSelect
        slidesToShow={width && width > 1024 ? 7 : 3}
        cssEase="ease-in-out"
        draggable
        pauseOnHover
        swipeToSlide
        beforeChange={(current, next) => {
          setActiveIndex(items[next].id)
        }}
        afterChange={(current) => sliderRef.current?.slickGoTo(current)}
        prevArrow={width && width < 1024 ? <></> : <CustomPrevArrow />}
        nextArrow={width && width < 1024 ? <></> : <CustomNextArrow />}
        dotsClass="slick-dots slick-thumb"
        centerPadding={width && width > 1024 ? "22rem" : "0"}
      >
        {items.map((item, idx) => {
          return (
            <div
              className="relative group my-20 !flex items-center justify-center md:-ms-80"
              key={item.name}
            >
              {hoveredIndex === item.id && (
                <motion.div
                  initial={{ opacity: 0, y: 20, scale: 0.6 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    transition: {
                      type: "spring",
                      stiffness: 260,
                      damping: 10,
                    },
                  }}
                  exit={{ opacity: 0, y: 20, scale: 0.6 }}
                  style={{
                    translateX: translateX,
                    rotate: rotate,
                    whiteSpace: "nowrap",
                  }}
                  className="absolute w-max -top-16 left-6 md:left-[3.5rem] translate-x-1/2 flex text-xs flex-col items-center justify-center rounded-md bg-black z-50 shadow-xl px-4 py-2"
                >
                  <div className="absolute inset-x-10 z-30 w-[20%] -bottom-px bg-gradient-to-r from-transparent via-emerald-500 to-transparent h-px " />
                  <div className="absolute left-10 w-[40%] z-30 -bottom-px bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px " />
                  <div className="font-bold text-white relative z-30 text-base">
                    {item.name}
                  </div>
                </motion.div>
              )}
              <img
                onMouseMove={handleMouseMove}
                height={100}
                width={100}
                src={item.image}
                alt={item.name}
                onClick={() => {
                  setActiveIndex(item.id)
                  sliderRef.current?.slickGoTo(idx)
                }}
                onMouseEnter={() => setHoveredIndex(item.id)}
                onMouseLeave={() => setHoveredIndex(null)}
                className={cn(
                  "object-cover shadow-xl !m-0 p-0 object-top rounded-full group-hover:scale-125 group-hover:z-30 relative transition duration-500",
                  {
                    "p-1 border-4 border-[#69947a] scale-150":
                      activeIndex === item.id,
                    "h-[90px] w-[90px]": width && width > 1024,
                    "h-[70px] w-[70px]": width && width <= 1024,
                  },
                  getOpacityClass(item.id)
                )}
              />
            </div>
          )
        })}
      </Slider>
    </>
  )
}
